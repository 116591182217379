import React, { useState, useEffect, FunctionComponent } from 'react';
import { Button, Grid, Typography, } from '@material-ui/core';
import { RouteComponentProps, Redirect } from "react-router"
import MiniDrawer from "../../common/components/SideDrawer"
import { runCustomScript,runUpdatedSchedules } from '../../../api/dashboard';
import { useSnackbar } from 'notistack';

interface Props extends RouteComponentProps {

}

const Dashboard: FunctionComponent<Props> = ({ location }) => {

	const {enqueueSnackbar} = useSnackbar()

	const [redirectTo, setRedirectTo] = useState('');
	var allowedPermissions: string[] = [];
	var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
	var loginUserType = '';
	if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
		allowedPermissions = loginUserData.adminRole.permissions;
		loginUserType = loginUserData.adminRole.name;
	}

	let welcomeMsg = 'Hello ' + (loginUserData && loginUserData.firstName ?
		(loginUserData.firstName + ' ' + (loginUserData.lastName ? loginUserData.lastName : '')) :
		'Admin'
	) + '!';

	localStorage.setItem('welcomeMsg', welcomeMsg)

	useEffect(() => {
		getLoggedInUserData()
	}, []);

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
	}

	// const updateNumbers = async() => {
	// 	try {
	// 		await runCustomScript()
	// 		enqueueSnackbar('Numbers updated successfully!', { variant: 'success' });
	// 	} catch (error) {
	// 		enqueueSnackbar("Number updation failed", { variant: 'warning' });
	// 	}
	// }

	// const updateSchedules = async() => {
	// 	try {
	// 		await runUpdatedSchedules()
	// 		enqueueSnackbar('Schedules updated successfully!', { variant: 'success' });
	// 	} catch (error) {
	// 		enqueueSnackbar("Schedule updation failed", { variant: 'warning' });
	// 	}
	// }

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<MiniDrawer>
			<div style={{ height: '80vh', width: '100%' }}>

				<Grid
					container
					// direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid style={{marginTop:"12px"}} item xs={12} md={8}>
						{/* <Typography >Dashboard</Typography> */}
						<Typography variant="h4" noWrap>
							Welcome to Edumatica Back Office!
						</Typography>

					</Grid>
					{/* <Grid style={{marginTop:"12px"}} item xs={12} md={8}>
						<Typography component="caption" variant="body2" noWrap>
							Please run the following script to update all user phone numbers
						</Typography>
					</Grid>
					<Grid style={{marginTop:"12px"}} item xs={12} md={8}>
						<Button disableElevation variant="outlined" size="large" color="primary" 
							onClick={() => {updateNumbers()}}
						>
							Update User Phone Numbers
						</Button>
					</Grid>

					<Grid style={{marginTop:"12px"}} item xs={12} md={8}>
						<Button disableElevation variant="outlined" size="large" color="primary" 
							onClick={() => {updateSchedules()}}
						>
							Update Schedules
						</Button>
					</Grid> */}
				</Grid>

			</div>
		</MiniDrawer>
	);
}

export default Dashboard
