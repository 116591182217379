import { ReactType } from "react"
import { OrgMgmt, TutorMgmt } from "./modules/orgMgmt/containers/index"
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import Login from "./modules/auth/containers/login";
import Dashboard from "./modules/dashboard/containers/dashboard";
import CreateUser from "./modules/dashboard/containers/manageUsers/createUser";
import ForgotPassword from "./modules/auth/containers/forgotPassword";
import CreateRole from "./modules/dashboard/containers/rolesAndPermissions/createRole";
import ResetPassword from "./modules/dashboard/containers/manageUsers/resetPassword";
import EditUser from "./modules/dashboard/containers/manageUsers/editUser";
import SetPassword from "./modules/auth/containers/setPassword";
import AssignRole from "./modules/dashboard/containers/manageUsers/assignRole";
import ViewEditPermissions from "./modules/dashboard/containers/rolesAndPermissions/ViewEditPermissions";
import ViewSearchAdmin from "./modules/dashboard/containers/manageUsers/viewSearchAdmin";
import SearchCustomer from "./modules/dashboard/containers/customerManagement/searchCustomer";

import EditCustomerRole from "./modules/dashboard/containers/customerManagement/editCutomersrole";

import ManageKYC from "./modules/dashboard/containers/customerManagement/manageKYC";
import ChangeBankAccount from "./modules/dashboard/containers/customerManagement/changeBankAccount";
import ViewCustomer from "./modules/dashboard/containers/customerManagement/viewCustomer";
import EditCusRole from "./modules/dashboard/containers/customerManagement/editCusRole";

import AddDevApi from "./modules/dashboard/containers/customerManagement/addDevApi";
import BlockUnblockUser from "./modules/dashboard/containers/customerManagement/blockUnblockUser";

import InvoiceDetails from "./modules/dashboard/containers/customerManagement/InvoiceDetails";
import JobPosting from "./modules/career/containers/jobPosting";
import UploadResource from "./modules/webResources/containers/uploadResource";
import LeadsEnquiry from "./modules/leadsManagement/containers/leadsEnquiry";
import Feedback from "./modules/feedback/containers/feedback";
import CreateJob from "./modules/career/containers/createJob";
import FeePackages from "./modules/dashboard/containers/feePackages/feePackages";
import AssignmentIcon from '@material-ui/icons/Assignment';

import Person from './assets/svgs/person1.svg';
import Permission from './assets/svgs/permission.svg';
import Statistics from './assets/svgs/statistics.svg';
import ManageAccounts from './assets/svgs/manage_accounts1.svg';
import Group from './assets/svgs/group.svg';
import ContactPhone from './assets/svgs/contact_phone.svg';
import Website from "./assets/svgs/website1.svg";
import Rupee from "./assets/svgs/rupee.svg";
import Upload from "./assets/images/upload.png";
import Finance from "./assets/images/finance.png";
import PreviousPackages from "./modules/dashboard/containers/feePackages/previousPackages";
import { MASTER_PERMISSIONS } from "./utilities/constants";
import MasterUploads from "./modules/masterData/containers/master_uploads";
import MasterNotificationTemplates from "./modules/masterData/containers/master_notification_templates";
import MasterQuestions from "./modules/masterData/containers/master_questions";
import ImageSorter from "./modules/masterData/containers/image_sorter"
import CertificateUpload from "./modules/masterData/containers/certificates"
import FinanceOperation from "./modules/finance/containers/finance_operations";
import FinanceEdumacData from "./modules/finance/containers/finance_edumacData";
import EditResources from "./modules/webResources/containers/editResources";
import EditJobPosting from "./modules/career/containers/editJobPosting";
import EditMasterQuestions from "./modules/masterData/containers/edit_master_questions"
import ViewLiveClasses from "./modules/liveClasses/containers/viewLiveClasses";
import ViewNotificationTemplates from "./modules/masterData/containers/view_master_notification_templates";
import SendEmail from "./modules/dashboard/containers/customerManagement/sendEmail";
import AddEduAcademyContent from "./modules/edumaticaAcademy/containers/addEduAcademyContent";
// import EditEduAcademyContent from "./modules/edumaticaAcademy/containers/editEduAcademyContent";
import EdumaticaStandardPage from "./modules/edumaticaStandards/containers/edumatica_standards";
import EdumacQuestionBank from "./modules/questionbank/containers/EdumacQuestionBank";
import ViewExceptionTrackers from "./modules/exceptionTracker/container/viewExceptionTrackers";
import ViewTutorPublicProfiles from "./modules/publicProfiles/containers/viewTutorPublicProfiles";
import ViewStudentInquiries from "./modules/publicProfiles/containers/viewStudentInquiries";
import ViewOtps from "./modules/dashboard/containers/customerManagement/viewOtps";
import ViewAcademicReports from "./modules/dashboard/containers/customerManagement/viewAcademicReports";
import SignUpUsers from "./modules/dashboard/containers/customerManagement/signUpUsers";
import CourseMarketplace from "./modules/dashboard/containers/customerManagement/courseMarketplace";
import CMPBatches from "./modules/dashboard/containers/customerManagement/cmpBatches";
import CMPStudents from "./modules/dashboard/containers/customerManagement/cmpStudents";
import CMPStudent from "./modules/dashboard/containers/customerManagement/cmpStudent";
import CMPFeeUpdate from "./modules/dashboard/containers/customerManagement/cmpFeeUpdate";
import ViewUsersOfEntity from "./modules/dashboard/containers/customerManagement/viewUsersOfEntity";
import UploadContentFile from "./modules/uploadContent/containers/uploadContentFile";
import ReceiptTemp from "./modules/receiptTemplate/container/receiptTemplate";
import SeoManager from "./modules/seoManagement/container/seoManager"
import ViewAppLogs from "./modules/appLogs/containers/viewAppLogs";
import collegeGroupsDashboard from "./modules/dashboard/containers/customerManagement/collegeGroupsDashboard";
import createGroup from "./modules/dashboard/containers/customerManagement/createGroup";
import viewCollegeGroup from "./modules/dashboard/containers/customerManagement/viewCollegeGroup";
import UpdateLogInUserId from "./modules/dashboard/containers/customerManagement/updateLogInUserId";

interface RouteElement {
	redirectTo: string,
	component: ReactType
	rolesExcluded: string[],
	includedInNavbar: boolean
	name?: string,
	permissions: string[],
}

interface CategorisedRouteElement {
	name: string,
	icon?: any,
	subMenus: RouteElement[],
	expanded: boolean,
}

const categorisedRoutesList: CategorisedRouteElement[] = [
	// {
	// 	redirectTo: '/login',
	// 	component: Login,
	// 	rolesExcluded: [],
	// 	includedInNavbar: false,
	// 	name: 'Login',
	// 	permissions: ['']
	// },
	// {
	// 	redirectTo: '/forgot-password',
	// 	component: ForgotPassword,
	// 	rolesExcluded: [],
	// 	includedInNavbar: false,
	// 	name: 'Forgot Password',
	// 	permissions: ['']
	// },
	// {
	// 	redirectTo: '/set-password',
	// 	component: SetPassword,
	// 	rolesExcluded: [],
	// 	includedInNavbar: false,
	// 	name: 'Set Password',
	// 	permissions: ['']
	// },
	// {
	// 	redirectTo: '/dashboard',
	// 	component: Dashboard,
	// 	rolesExcluded: [],
	// 	includedInNavbar: false,
	// 	name: 'Dashboard',
	// 	permissions: ['']
	// },
	{
		name: 'Manage Users',
		icon: Person,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/create-user',
				component: CreateUser,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Create User',
				permissions: [MASTER_PERMISSIONS.createAdmin]
			},
			{
				redirectTo: '/search-admin',
				component: ViewSearchAdmin,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Search/Edit Users',
				permissions: [MASTER_PERMISSIONS.getAdmins,]
			},
			{
				redirectTo: '/edit-user',
				component: EditUser,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Edit User',
				permissions: [MASTER_PERMISSIONS.editAdmin,]
			},
			{
				redirectTo: '/reset-password',
				component: ResetPassword,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Reset Password',
				permissions: [MASTER_PERMISSIONS.resetPassword, MASTER_PERMISSIONS.sendNewPasswordMail,]
			},
			{
				redirectTo: '/assign-role',
				component: AssignRole,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Assign Role',
				permissions: [MASTER_PERMISSIONS.editAdmin,]
			},
		]
	},
	{
		name: 'Roles & Permissions',
		icon: Permission,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/create-role',
				component: CreateRole,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Create Role',
				permissions: [MASTER_PERMISSIONS.createRole, MASTER_PERMISSIONS.getRoles,]
			},
			{

				redirectTo: '/view-permissions',
				component: ViewEditPermissions,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'View/Edit Role',
				permissions: [MASTER_PERMISSIONS.editRole, MASTER_PERMISSIONS.getRoles,]
			},
		]
	},
	{
		name: 'Customer Management',
		icon: Group,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/search-customer',
				component: SearchCustomer,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Search Customers',
				permissions: [MASTER_PERMISSIONS.getUsers,]
			},
			{
				redirectTo: '/college-groups',
				component: collegeGroupsDashboard,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'College Groups',
				permissions: [MASTER_PERMISSIONS.getUsers,]
			},
			{
				redirectTo: '/editCustomerRrole',
				component: EditCustomerRole,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edit Customer Role',
				permissions: [MASTER_PERMISSIONS.getUsers,]
			},
			{
				redirectTo: '/update-bank-account',
				component: ChangeBankAccount,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Change Bank Account',
				permissions: [MASTER_PERMISSIONS.makerBankChange, MASTER_PERMISSIONS.checkerBankChange,]
			},
			{
				redirectTo: '/view-customer',
				component: ViewCustomer,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'View Customer',
				permissions: [MASTER_PERMISSIONS.getUser,]
			},
			{
				redirectTo: '/create-group',
				component: createGroup,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Create Group',
				permissions: [MASTER_PERMISSIONS.getUser,]
			},
			{
				redirectTo: '/View-group',
				component: viewCollegeGroup,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'View Group',
				permissions: [MASTER_PERMISSIONS.getUser]
			},
			{
				redirectTo: '/edit-customer',
				component: EditCusRole,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Edit Customer Role',
				permissions: [MASTER_PERMISSIONS.getUser,]
			},
			{
				redirectTo: '/addDevApi',
				component: AddDevApi,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Add Dev Api',
				permissions: [MASTER_PERMISSIONS.getUser,]
			},
			{
				redirectTo: '/block-user',
				component: BlockUnblockUser,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Block/Unblock',
				permissions: [MASTER_PERMISSIONS.updateUserStatus,]
			},
			{
				redirectTo: '/log-in-user',
				component: UpdateLogInUserId,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'LogInUser',
				permissions: [MASTER_PERMISSIONS.updateUserStatus,]
			},
			{
				redirectTo: '/viewUsers',
				component: ViewUsersOfEntity,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'View Users',
				permissions: [MASTER_PERMISSIONS.consolidatedReport]
			},
			{
				redirectTo: '/invoice',
				component: InvoiceDetails,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'Invoice',
				permissions: ['']
			},
			{
				redirectTo: '/manageKYC',
				component: ManageKYC,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Manage KYC',
				permissions: [MASTER_PERMISSIONS.makerKyc, MASTER_PERMISSIONS.checkerKyc,]
			},
			{
				redirectTo: '/sendEmail',
				component: SendEmail,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Send Email',
				permissions: [MASTER_PERMISSIONS.sendEmail]
			},
			{
				redirectTo: '/tutorPublicProfiles',
				component: ViewTutorPublicProfiles,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Tutor Public Profiles',
				permissions: [MASTER_PERMISSIONS.tutorPublicProfiles]
			},
			{
				redirectTo: '/studentInquiries',
				component: ViewStudentInquiries,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Student Inquiries',
				permissions: [MASTER_PERMISSIONS.studentInquiries]
			},
			{
				redirectTo: '/exceptionTrackers',
				component: ViewExceptionTrackers,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Exception Reports',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/otps',
				component: ViewOtps,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'OTP Reports',
				permissions: [MASTER_PERMISSIONS.otps]
			},
			{
				redirectTo: '/academicReports',
				component: ViewAcademicReports,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'User Engagement Reports',
				permissions: [MASTER_PERMISSIONS.academicDetails]
			},
			{
				redirectTo: '/cmpreports/feeupdate',
				component: CMPFeeUpdate,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'CMP FeeUpdate',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/cmpreports/studentdetail',
				component: CMPStudent,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'CMP Student',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/cmpreports/students',
				component: CMPStudents,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'CMP Students',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/cmpreports/batches',
				component: CMPBatches,
				rolesExcluded: [],
				includedInNavbar: false,
				name: 'CMP Batches',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/cmpreports',
				component: CourseMarketplace,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Course Marketplace',
				permissions: [MASTER_PERMISSIONS.edumacException]
			},
			{
				redirectTo: '/usersignup',
				component: SignUpUsers,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Sign up Users',
				permissions: [MASTER_PERMISSIONS.edumacException]
			}
		]
	},
	{
		name: 'Live Classes',
		icon: Group,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/liveClasses',
				component: ViewLiveClasses,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'View Live Classes',
				permissions: [MASTER_PERMISSIONS.liveClasses]
			}
		]
	},
	{
		name: 'Fee Packages',
		icon: Rupee,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/fee-packages',
				component: FeePackages,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Create Fee Packages',
				permissions: [MASTER_PERMISSIONS.edumacpackage]
			},
			{
				redirectTo: '/historical-packages',
				component: PreviousPackages,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Historical Packages',
				permissions: [MASTER_PERMISSIONS.packageapproval]
			},
		]
	},
	{
		name: 'Finance Operation',
		icon: Finance,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/financeoperations',
				component: FinanceOperation,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Operations',
				permissions: [MASTER_PERMISSIONS.payorder]
			},
			{
				redirectTo: '/financeedumacdata',
				component: FinanceEdumacData,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Financial Data',
				permissions: [MASTER_PERMISSIONS.payorder]
			},
		]
	},
	{
		name: 'Leads Management',
		icon: ContactPhone,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/leads-Enquiry',
				component: LeadsEnquiry,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Leads Enquiry',
				permissions: [MASTER_PERMISSIONS.getLeads, MASTER_PERMISSIONS.updateLeads]
			},
			{
				redirectTo: '/demo-enquiry',
				component: LeadsEnquiry,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Demo Enquiry',
				permissions: [MASTER_PERMISSIONS.getLeads, MASTER_PERMISSIONS.updateLeads]
			}
		]
	},

	{
		name: 'Career Enquiry',
		icon: ManageAccounts,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/create-job',
				component: CreateJob,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Create Job Posting',
				permissions: [MASTER_PERMISSIONS.jobposting, MASTER_PERMISSIONS.getJobId]
			},
			{
				redirectTo: '/jobposting',
				component: JobPosting,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Career Enquiry',
				permissions: [MASTER_PERMISSIONS.getSpreadsheet, MASTER_PERMISSIONS.updateApplicant, MASTER_PERMISSIONS.updateApplicants]
			},
			{
				redirectTo: '/edit-jobposting',
				component: EditJobPosting,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edit Job Posting',
				permissions: [MASTER_PERMISSIONS.getJobsForBO, MASTER_PERMISSIONS.updateJob]
			}
		]
	},
	{
		name: 'Website Contents',
		icon: Website,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/upload-blogs',
				component: UploadResource,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Blogs Upload',
				permissions: [MASTER_PERMISSIONS.createContent]
			},
			{
				redirectTo: '/upload-e-book',
				component: UploadResource,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'E-books Upload',
				permissions: [MASTER_PERMISSIONS.createContent]
			},
			{
				redirectTo: '/upload-video',
				component: UploadResource,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Video Upload',
				permissions: [MASTER_PERMISSIONS.createContent]
			},
			{
				redirectTo: '/feedback',
				component: Feedback,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Feedback',
				permissions: [MASTER_PERMISSIONS.createFeedback]
			},
			{
				redirectTo: '/edit-resources',
				component: EditResources,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edit Resources',
				permissions: [MASTER_PERMISSIONS.updateContent]
			},
		]
	},
	{
		name: 'Edumatica QB',
		icon: Website,
		expanded: false,
		subMenus: [			
			{
				redirectTo: '/edumaticaQB',
				component: EdumacQuestionBank,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Add Edumatica QB',
				permissions: [MASTER_PERMISSIONS.edumaticaQuestionBanks]
			},
		]
	},
	{
		name: 'Edumatica Academy',
		icon: Upload,
		expanded: false,
		subMenus: [			
			{
				redirectTo: '/addEdumaticaAcademyContent',
				component: AddEduAcademyContent,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Add Edumatica Academy Content',
				permissions: [MASTER_PERMISSIONS.edumaticaAcademy]
			},
			// {
			// 	redirectTo: '/editEdumaticaAcademyContent',
			// 	component: EditEduAcademyContent,
			// 	rolesExcluded: [],
			// 	includedInNavbar: true,
			// 	name: 'Edit Edumatica Academy Contents',
			// 	permissions: [MASTER_PERMISSIONS.edumaticaAcademy]
			// }
		]
	},
	{
		name: 'Content Data',
		icon: Upload,
		expanded: false,
		subMenus: [			
			{
				redirectTo: '/uploadContentFile',
				component: UploadContentFile,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Add Board Content',
				permissions: []
			},
			// {
			// 	redirectTo: '/editEdumaticaAcademyContent',
			// 	component: EditEduAcademyContent,
			// 	rolesExcluded: [],
			// 	includedInNavbar: true,
			// 	name: 'Edit Edumatica Academy Contents',
			// 	permissions: [MASTER_PERMISSIONS.edumaticaAcademy]
			// }
		]
	},
	{
		name: 'Master Data',
		icon: Upload,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/masteruploads',
				component: MasterUploads,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Upload Data',
				permissions: [MASTER_PERMISSIONS.uploadCities]
			},
			{
				redirectTo: '/notification_templates',
				component: MasterNotificationTemplates,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Upload Notification Templates',
				permissions: [MASTER_PERMISSIONS.msgtemplate]
			},
			{
				redirectTo: '/view_notification_templates',
				component: ViewNotificationTemplates,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edit Notification Templates',
				permissions: [MASTER_PERMISSIONS.masterNotificationTemplate]
			},
			{
				redirectTo: '/masterQuestions',
				component: MasterQuestions,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Master Questions',
				permissions: [MASTER_PERMISSIONS.uploadQuestions]
			},
			{
				redirectTo: '/editMasterQuestions',
				component: EditMasterQuestions,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edit Master Questions',
				permissions: [MASTER_PERMISSIONS.masterQuestions]
			},
			{
				redirectTo: '/edumaticaStandards',
				component: EdumaticaStandardPage,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Edumatica Standard Identifiers',
				permissions: [MASTER_PERMISSIONS.edumaticaStandards]
			},
			{
				redirectTo: '/imagesorter',
				component: ImageSorter,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Image Sorter',
				permissions: [MASTER_PERMISSIONS.imagesorter]
			},
			{
				redirectTo: '/certificateupload',
				component: CertificateUpload,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Certificate Upload',
				permissions: []
			},
			{
				redirectTo: '/receiptTemp',
				component: ReceiptTemp,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Receipt Template',
				permissions: []
			},
		]
	},
	{
		name: 'Meta Data',
		icon: Upload,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/seoManager',
				component: SeoManager,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'Upload Data',
				permissions: [MASTER_PERMISSIONS.seoManager]
			},
			
		]
	},
	{
		name: 'App Logs',
		icon: Website,
		expanded: false,
		subMenus: [
			{
				redirectTo: '/viewAppLogs',
				component: ViewAppLogs,
				rolesExcluded: [],
				includedInNavbar: true,
				name: 'View Logs',
				permissions: [MASTER_PERMISSIONS.viewAppLogs]
			},
			
		]
	},
]

export default categorisedRoutesList