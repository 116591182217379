// export const ROOT_URL = 'https://edumatica.tk/api/';
export const ROOT_URL = process.env.REACT_APP_API;
export const BASE_URL_AUTH = ROOT_URL + "adminAuth/";
export const BASE_URL = ROOT_URL + "admin/";
export const BASE_URL_2 = process.env.REACT_APP_API_2;
const LIBRARY = ROOT_URL + "library";

export const TEMP_URL = "http://localhost:3004/";

export const POST_USER_LOGIN = BASE_URL_AUTH + "authenticate";
export const GET_OTP = BASE_URL_AUTH + "getOtp";
export const VERIFY_OTP = BASE_URL_AUTH + "verifyOtp";
export const SET_PASSWORD = BASE_URL_AUTH + "setPassword";
export const VERIFY_CAPTCHA = ROOT_URL + "auth/verifyCaptcha";
export const CREATE_COLLEGE_GROUP = LIBRARY + "/addGenericCollegeGroupMapping";
export const GET_ALL_COLLEGE_GROUPS = LIBRARY + "/getGenericCollegeGroupMapping";
export const DELETE_COLLEGE_GROUP = LIBRARY + "/deleteGenericCollegeGroupMapping";
export const UPDATE_COLLEGE_GROUP = LIBRARY + "/editGenericCollegeGroupMapping";

export const ORG_THEMES_DATA = BASE_URL + "/themes";
export const SET_THEME = BASE_URL + "/setCurrentTheme";
export const GET_THEME = BASE_URL + "/getCurrentTheme";

export const GET_GROUP_BY_OWNERID = LIBRARY + "/getGenericCollegeGroupCodeByOwnerId";

export const GET_CURRENT_LOGO_AND_SUBDOMAIN =
  BASE_URL + "/getCurrentLogoAndSubdomain";
export const SET_CURRENT_LOGO_AND_SUBDOMAIN =
  BASE_URL + "/setCurrentLogoAndSubdomain";

export const UPDATE_BLOCKED_PERMISSION = BASE_URL + "updatePermission";
export const GET_BLOCKED_PERMISSION = BASE_URL + "getPermission";

export const CREATE_ADMIN_USER = BASE_URL + "createAdmin";
export const CREATE_ROLE = BASE_URL + "createRole";
export const GET_ROLES = BASE_URL + "getRoles";
export const GET_PERMISSIONS = BASE_URL + "getPermissions";
export const GET_ADMINS = BASE_URL + "getAdmins";
export const UPDATE_DEFAULT_PASSWORD = BASE_URL + "changePassword";
export const EDIT_ADMIN_USER = BASE_URL + "editAdmin";
export const RESET_PASSWORD_BY_ADMIN = BASE_URL + "resetPassword";
export const ASSIGN_ROLE = BASE_URL + "assignRole";
export const GET_USERS = BASE_URL + "getUsers";
export const GET_ROLES_SECAND = BASE_URL + "getRolesSecand";
export const GET_USERS_DETAILS_ROLE = BASE_URL + "getUserDetails";

export const GET_ORGANIZATIONS_LIST = ROOT_URL + 'admin/getOrganizationList';


// New Added user search
export const GET_USERS_DETAILS = BASE_URL + "userDetails";
export const FETCH_USERS = BASE_URL + "fetchusers";
export const PUBLIC_COURSE = BASE_URL + "publiccourse";
export const PUBLIC_COURSE_BU = BASE_URL + "publiccourse/blockstatus";
export const PUBLIC_COURSE_BATCH = BASE_URL + "publiccourse/batch";
export const PUBLIC_COURSE_STUDENT = BASE_URL + "publiccourse/student";
export const PUBLIC_COURSE_BATCHFEE = BASE_URL + "publiccourse/batchfee";
export const PUBLIC_COURSE_STUDENTSTATUS =
  BASE_URL + "publiccourse/studentstatus";
export const PUBLIC_COURSE_STUDENTCERTIFICATE =
  BASE_URL + "publiccourse/studentcertificate";
export const PUBLIC_COURSE_SALEDETAIL = BASE_URL + "publiccourse/saledetail";
export const PUBLIC_COURSE_SALE = BASE_URL + "publiccourse/sale";
export const VERIFY_USER = BASE_URL + "validateuser";
export const REG_USER = BASE_URL + "registeruser";
export const UPDATE_USER_PREFERENCES = BASE_URL + "userPreferences";
export const UPDATE_USER_STATUS = BASE_URL + "updateUserStatus";
export const UPDATE_USER_LOGIN = BASE_URL + "updateUserLoginIds";
export const UPDATE_BANK_ACCOUNT = BASE_URL + "changeBankAccount";
export const GET_KYC_DOCUMENT_UPLOAD_URL = BASE_URL + "getUploadUrlForKycDoc";
export const GET_USER = BASE_URL + "getUser";
export const DELETE_ADMIN = BASE_URL + "deleteAdmin";
export const EDIT_ROLE = BASE_URL + "editRole";
export const UPDATE_ENROLL = BASE_URL + "updateAllEnrollmentId";
export const GET_ADMIN = BASE_URL + "getLoggedAdmin";
export const POST_EMAIL_PASWORD = BASE_URL + "sendNewPasswordMail";
export const GET_KYC_STATUS_LIST = BASE_URL + "fetchKycStatus";
export const GET_KYC_DATA = BASE_URL + "fetchKycData";
export const UPDATE_KYC_DATA = BASE_URL + "updateKycStatus";
export const UPDATE_USER_DETAILS = BASE_URL + "updateUserDetails";
export const DELETE_USER = BASE_URL + "deleteUser";
export const HARD_DELETE_USER = BASE_URL + "hardDeleteUser";



export const DELETE_KYC_DOCUMENT = BASE_URL + "uploadedKYCDoc";
export const GET_KYC_DOCUMENT_DOWNLOAD_URL =
  BASE_URL + "getDownloadUrlForKycDoc";
export const GET_PACKAGES = BASE_URL_2 + "finance/edumacpackage";
export const GET_KYC_VIDEO_DOWNLOAD_URL =
  BASE_URL + "getDownloadUrlForVideoKyc";

export const GET_CUSTOMER_PACKAGE = ROOT_URL + "finance/purchasedpackage";
export const CUSTOMER_PACKAGE_APPROVAL = ROOT_URL + "finance/packageapproval";
export const GET_PAYMENT_ORDER = ROOT_URL + "finance/payorder";
export const UPDATE_PAYMENT_ORDER = ROOT_URL + "payments/updatePayOrders";

export const RUN_CUSTOM_SCRIPT = BASE_URL + "customScript";
export const RUN_UPDATED_SCHEDULES = BASE_URL + "makeScheduleUpdates";
