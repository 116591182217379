import { createAction } from '@reduxjs/toolkit';
import { Admin, CustomUser } from '../../common/contracts/dashboard';

export const setCurrentAdmin = createAction('SET_CURRENT_ADMIN',
	(admin: Admin) => ({ payload: admin })
);

export const setCurrentUser = createAction('SET_CURRENT_USER',
	(user: CustomUser) => ({ payload: user })
);

export const setLoginUserData = createAction('SET_LOGIN_USER_DATA', (data) => {
	localStorage.setItem('loginUserData', data);
	return { payload: data };
});

export const setCustomersList = createAction('SET_CUSTOMERS_LIST', (data: CustomUser[]) => {
	return { payload: data };
});

export const setAdminsList = createAction('SET_ADMINS_LIST', (data: Admin[]) => {
	return { payload: data };
});

export const updateAdminRole = createAction('UPDATE_ADMIN_ROLE', (data: string) => {
	return { payload: data };
})