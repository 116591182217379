import { combineReducers, createReducer } from "@reduxjs/toolkit";

import { Admin, CustomUser } from "../../common/contracts/dashboard";
import { setLoginUserData, setCurrentAdmin, setCurrentUser, setCustomersList, setAdminsList } from "../actions/adminActions";

// const ADMIN: Admin = {
// 	name: '',
// 	enrollmentId: '',
// 	mobileNo: '',
// 	parentMobileNo: '',
// 	email: '',
// 	pincode: '',
// 	city: '',
// 	state: '',
// 	board: '',
// 	classname: '',
// 	schoolname: '',
// 	roleStatus: 'ACTIVE'
// };
const admin = createReducer({}, {
	[setCurrentAdmin.type]: (_, action) => action.payload
});

const User: CustomUser = {
	_id: '',
	name: '',
	mobileNo: '',
	emailId: '',
	userType: ''
}

const user = createReducer(User, {
	[setCurrentUser.type]: (_, action) => action.payload
});

const loginUser = createReducer({}, {
	[setLoginUserData.type]: (_, action) => action.payload
});

const customers_List: CustomUser[] = []
const customersList = createReducer(customers_List, {
	[setCustomersList.type]: (_, action) => action.payload
});

const admins_List: Admin[] = []
const adminsList = createReducer(admins_List, {
	[setAdminsList.type]: (_, action) => action.payload
});

// const admins_List: Admin[] = []
// const adminsList = createReducer(admins_List, (builder) => {
// 	builder.addCase(setAdminsList.type, (state, action) => {
// 		state = action.payload;
// 	})
// });

export const adminReducer = combineReducers({
	admin,
	user,
	loginUser,
	customersList,
	adminsList
});